import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type StudentProgressService from 're-client/services/student-progress'
import type { ModelFor } from 're-client/utils/route-model'
import type DrivingTestsRacingGameRoute from 're-client/routes/driving-tests/racing-game'
import type AssignmentsService from 're-client/services/assignments'
import type LogoutService from 're-client/services/logout'
import { graphql } from 're-client/graphql'
import type { FeatureService } from '@blakeelearning/features'
import type ErrorHandlerService from 're-client/services/error-handler'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'

export const saveDrivingTestGameResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveDrivingTestGameResult(
    $input: DrivingTestGameResultCreateInput!
  ) {
    drivingTestGameResultCreate(input: $input) {
      student {
        id
        eggs
        ...StudentProgressFragment
      }
    }
  }
`)
export default class DrivingTestsRacingGameController extends Controller {
  @service
  declare operations: Operations

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare assignments: AssignmentsService

  @service
  declare logout: LogoutService

  @service
  declare features: FeatureService

  @service
  declare errorHandler: ErrorHandlerService

  saveDrivingTestGameResultMutation = useMutation(
    this,
    saveDrivingTestGameResultMutationDocument,
  )

  declare model: ModelFor<DrivingTestsRacingGameRoute>

  get shouldLogout() {
    return (
      this.user.student.rosterEnabled &&
      this.model.assignmentMode &&
      !this.assignments.currentTask
    )
  }

  @action
  async incrementScore(eggs = 1, uuid?: string) {
    if (this.features.isEnabled('graphql_driving_tests')) {
      try {
        const data =
          await this.saveDrivingTestGameResultMutation.current.mutate({
            variables: {
              input: {
                eggs,
                attemptUuid: uuid ?? uuidV4(),
              },
            },
          })

        if (!data) {
          throw new Error(
            '[SaveDrivingTestGameResult] mutation returned no data',
          )
        }
      } catch (error) {
        this.errorHandler.handleError(
          '[SaveDrivingTestGameResult] mutation failed',
          error,
        )
      }
    } else {
      await this.operations.completeDrivingTestGame(
        { precinct: 'driving_tests' },
        { eggs },
      )
      await this.studentProgress.fetchProgress()
      this.user.incrementEggs(eggs)
    }
  }

  @action
  exit() {
    if (this.shouldLogout) {
      this.logout.doLogout()
      return
    }

    this.router.transitionTo('driving-tests')
  }
}
