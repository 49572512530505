import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import type Store from '@ember-data/store'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type { FeatureService } from '@blakeelearning/features'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ErrorLike } from 're-client/utils/errors'

export default class HouseController extends Controller {
  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare store: Store

  @service
  declare features: FeatureService

  @service declare loadingUi: LoadingUiService

  @service declare errorHandler: ErrorHandlerService

  get student() {
    return this.user.student
  }

  get isJesterHouse() {
    return this.features.isEnabled('jester_my_house_enabled')
  }

  get contentSpec() {
    return {
      updateItem: {
        type: 'message',
      },
      goToBookCase: {
        type: 'message',
      },
      goToChangeRoom: {
        type: 'message',
      },
      goToCritterBook: {
        type: 'message',
      },
      goToGamesMachine: {
        type: 'message',
      },
      goToTrophies: {
        type: 'message',
      },
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
    }
  }

  @action
  updateItem({ id, state }: { id: string; state: string }) {
    const itemRecord = this.store.peekRecord('item', id)

    if (itemRecord) {
      itemRecord.state = state
      itemRecord.save()
    }
  }

  @action
  showCritters() {
    this.router.transitionTo('critters')
  }

  @action
  showChangeRoom() {
    this.router.transitionTo('changing-room')
  }

  @action
  showBookCase() {
    this.router.transitionTo('bookcase')
  }

  @action
  showAwards() {
    this.router.transitionTo('awards')
  }

  @action
  showArcade() {
    this.router.transitionTo('games')
  }

  @action
  goToBookCase() {
    this.showBookCase()
  }

  @action
  goToChangeRoom() {
    this.showChangeRoom()
  }

  @action
  goToCritterBook() {
    this.showCritters()
  }

  @action
  goToGamesMachine() {
    this.showArcade()
  }

  @action
  goToTrophies() {
    this.showAwards()
  }

  @action
  readyForUserInput() {
    this.loadingUi.hide() // clear the loading screen
  }

  @action
  unhandledError({ error }: { error: Partial<ErrorLike> }) {
    this.errorHandler.handleContentUnhandledError(error)
  }
}
