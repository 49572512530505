import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>", {"contents":"<div class=\"content-interactive\" ...attributes>\n  <ContentLoader::Interactive\n    @interactiveConfig={{@interactiveConfig}}\n    @externalController={{this}}\n  />\n</div>","moduleName":"re-client/components/content-interactives/book-reader.hbs","parseOptions":{"srcName":"re-client/components/content-interactives/book-reader.hbs"}});
import Component from '@glimmer/component'

export default class BookReader extends Component {
  next() {
    this.args.nextAction()
  }

  resultVideo(videoTitle) {
    this.args.resultVideoAction(videoTitle)
  }

  backToQuiz() {
    this.args.backToQuizAction()
  }

  async saveProgress() {
    // As of July 2021, none of the invocations of this component do anything for
    // saveProgress - they return resolved promises that are no-ops. As such, we
    // call 'nextable' immediately now.
    return this._handleNextableResponse({})
  }

  async saveBookQuizScore(bookCode, total, correct) {
    const response = await this.args.saveBookQuizScoreAction(
      bookCode,
      total,
      correct,
    )
    return this.interactive.callInteractionMethod('handleQuizSuccess', response)
  }

  _handleNextableResponse(response) {
    const data = { reward: { eggs: 0 }, ...response }
    return this.interactive.callInteractionMethod('nextable', data)
  }
}
