import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { graphql } from 're-client/graphql'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type { ParamsFor } from 're-client/utils/route-model'
import type ReaderBookRoute from 're-client/routes/reader/book'
import type ApolloService from 're-client/services/apollo'
import type { FeatureService } from '@blakeelearning/features'

export const bookQueryDocument = graphql(/* GraphQL */ `
  query Book($input: BookQueryInput!) {
    book(input: $input) {
      content
    }
  }
`)

export default class ReaderBookIndexRoute extends Route {
  @service
  declare apollo: ApolloService

  @service
  declare features: FeatureService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  override queryParams = {
    disableQuiz: {
      replace: true,
    },
  }

  override async model({ disableQuiz }: Record<string, string>) {
    const { book_id: bookId } = this.paramsFor(
      'reader.book',
    ) as ParamsFor<ReaderBookRoute>

    // Load the Fable book representation if the flag is enabled.  For now, we
    // also continue to load the legacy version too via
    // `caperLoader.readingBookRequest` below, as the necessary updates to the
    // Caper book reader may not have been deployed.
    let bookContent: unknown
    if (this.features.isEnabled('fable_books')) {
      const { data } = await this.apollo.query({
        query: bookQueryDocument,
        variables: {
          input: { code: bookId },
        },
        fetchPolicy: 'cache-first',
      })

      bookContent = data.book?.content
    }

    const variables: Record<string, unknown> = {
      student: this.user.student,
      bookContent,
      disableQuiz: !!disableQuiz,
    }

    // The Caper book reader will support `disableQuiz` (above) in the future,
    // as an alternative to doing this.
    if (disableQuiz) variables['quiz'] = null

    const manifests = [
      this.urlMaker.urlForInteractive('reading', 'reading'),
      this.caperLoader.readingBookRequest(bookId),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
    }
  }
}
