import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import ky from 'ky'
import prefetch from 're-client/utils/prefetch'
import type { FeatureService } from '@blakeelearning/features'
import type RouterService from '@ember/routing/router-service'
import type LoadingUiService from 're-client/services/loading-ui'
import type ErrorHandlerService from 're-client/services/error-handler'
import type { ErrorLike } from 're-client/utils/errors'

interface SaveAvatarOptions {
  DNA: string
}

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class ChangingRoomController extends Controller {
  @service
  declare user: UserService

  @service
  declare features: FeatureService

  @service declare router: RouterService

  @service declare loadingUi: LoadingUiService

  @service declare errorHandler: ErrorHandlerService

  get student() {
    return this.user.student
  }

  @action
  async saveAvatar({ DNA: dna }: SaveAvatarOptions) {
    await ky.put('avatar', {
      credentials: 'include',
      json: { dna },
      prefixUrl,
    })

    // could probably just update the apollo cache here with the response from the ajax request
    await this.user.fetch()
    prefetch(this.user.student.avatarUrl)
  }

  get isJesterChangingRoom() {
    return this.features.isEnabled('jester_map_enabled')
  }

  get contentSpec() {
    return {
      saveAvatar: {
        type: 'message',
      },
      goToShop: {
        type: 'message',
      },
      readyForUserInput: {
        type: 'message',
      },
      unhandledError: {
        type: 'message',
      },
    }
  }

  @action
  goToShop() {
    this.router.transitionTo('shop')
  }

  @action
  readyForUserInput() {
    this.loadingUi.hide() // clear the loading screen
  }

  @action
  unhandledError({ error }: { error: Partial<ErrorLike> }) {
    this.errorHandler.handleContentUnhandledError(error)
  }
}

declare module '@ember/controller' {
  interface Registry {
    'changing-room': ChangingRoomController
  }
}
