import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type Store from '@ember-data/store'
import type LocationTrackerService from 're-client/services/location-tracker'
import type RouterService from '@ember/routing/router-service'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'
import type ErrorHandlerService from 're-client/services/error-handler'

export const saveBookQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveBookQuizResult($input: BookQuizResultCreateInput!) {
    bookQuizResultCreate(input: $input) {
      bookQuizResult {
        reward {
          eggs
          milestone
          passed
        }
      }
      student {
        id
        eggs
        ...StudentProgressFragment
        ...AssignmentTask
      }
    }
  }
`)
export default class ReaderBookController extends Controller {
  @service
  declare locationTracker: LocationTrackerService

  @service
  declare router: RouterService

  @service
  declare store: Store

  @service
  declare errorHandler: ErrorHandlerService

  saveBookQuizResultMutation = useMutation(
    this,
    saveBookQuizResultMutationDocument,
  )

  @action
  next() {
    const { route, params } = this.locationTracker.getPreviousRoute()
    this.router.transitionTo(
      route,
      // @ts-expect-error router params type no bueno
      ...params,
    )
  }

  @action
  resultVideo(videoTitle: string) {
    this.router.transitionTo('reader.result', videoTitle)
  }

  @action
  async saveBookQuizScore(
    code: string,
    totalCount: number,
    correctCount: number,
    uuid?: string,
  ) {
    try {
      const data = await this.saveBookQuizResultMutation.current.mutate({
        variables: {
          input: {
            attemptUuid: uuid ?? uuidV4(),
            bookCode: code,
            correctCount,
            totalCount,
          },
        },
      })

      if (!data)
        throw new Error('[SaveBookQuizResult] No data returned from mutation')

      const { reward } = data.bookQuizResultCreate.bookQuizResult

      if (reward.passed) {
        // NOTE: Once user has successfully read a book & passed the quiz we know
        // this book should be shown on the bookshelf, hence instead of
        // reloading the bookshelf route every time, it is better to do
        // it here. reload() is more reliable than { reload: true }
        // findRecord will fetch cached data & reload() will refetch the request
        const bookshelf = await this.store.findRecord('bookshelf', 'bookshelf')
        await bookshelf.reload()
      }
      return reward
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveBookQuizResult] mutation failed',
        error,
      )
      return
    }
  }
}
