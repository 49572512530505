import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import { underscore } from '@ember/string'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type AssignmentsService from 're-client/services/assignments'
import type DrivingTestsQuizRoute from 're-client/routes/driving-tests/quiz'
import type { ModelFor } from 're-client/utils/route-model'
import type LogoutService from 're-client/services/logout'
import { graphql } from 're-client/graphql'
import type { FeatureService } from '@blakeelearning/features'
import type ErrorHandlerService from 're-client/services/error-handler'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'

const {
  APP: { product },
} = config

interface DrivingTestResults {
  quiz: number
  test_type: string
  correct_answers: string[]
  incorrect_answers: Record<string, string>
  passed: boolean
}

interface DrivingTestQuizResults extends DrivingTestResults {
  category: string
  grade_position?: number
}

interface StudentContext {
  product: string
  precinct: string
  remoteId: string
  assignmentUuid?: string
  taskId?: string
}

export const saveDrivingTestQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveDrivingTestQuizResult(
    $input: DrivingTestQuizResultCreateInput!
  ) {
    drivingTestQuizResultCreate(input: $input) {
      student {
        id
        ...StudentProgressFragment
        ...AssignmentTask
      }
    }
  }
`)

export default class DrivingTestsQuizController extends Controller {
  declare model: ModelFor<DrivingTestsQuizRoute>

  @service declare studentProgress: StudentProgressService

  @service declare operations: Operations

  @service declare router: RouterService

  @service declare user: UserService

  @service declare assignments: AssignmentsService

  @service declare logout: LogoutService

  @service declare features: FeatureService

  @service declare errorHandler: ErrorHandlerService

  saveDrivingTestQuizResultMutation = useMutation(
    this,
    saveDrivingTestQuizResultMutationDocument,
  )

  get isAssignmentMode() {
    return this.model?.isAssignmentMode
  }

  get studentContext() {
    return {
      product,
      precinct: 'driving_tests',
      remoteId: this.user.student.remoteId,
    }
  }

  get shouldLogout() {
    return (
      this.user.student.rosterEnabled &&
      this.isAssignmentMode &&
      !this.assignments.currentTask
    )
  }

  @action
  goToLobby() {
    if (this.shouldLogout) {
      this.logout.doLogout()
      return
    }
    void this.router.transitionTo('driving-tests')
  }

  @action
  startRace() {
    void this.router.transitionTo('driving-tests.racing-game', {
      queryParams: {
        assignmentMode: this.isAssignmentMode,
      },
    })
  }

  @action
  async completeDrivingTest(results: DrivingTestResults, uuid?: string) {
    this.user.setRacingGameAvailability(results.passed)
    const category = underscore(results.test_type)

    if (this.features.isEnabled('graphql_driving_tests')) {
      let assignment = null
      try {
        if (
          this.assignments.currentTask?.__typename ===
            'AssignmentTaskDrivingTests' &&
          this.assignments.canCompleteDrivingTestAssignmentTask(
            results.quiz,
            category,
          )
        ) {
          assignment = {
            assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
            assignmentUuid: this.assignments.currentTask.assignmentUuid,
            gradePosition: this.assignments.currentTask.gradePosition,
          }
        }

        const data =
          await this.saveDrivingTestQuizResultMutation.current.mutate({
            variables: {
              input: {
                correctAnswers: results.correct_answers,
                incorrectAnswers: results.incorrect_answers,
                category,
                quizPosition: results.quiz,
                attemptUuid: uuid ?? uuidV4(),
                assignment,
              },
            },
          })

        if (!data) {
          throw new Error(
            '[SaveDrivingTestQuizResult] mutation returned no data',
          )
        }
      } catch (error) {
        this.errorHandler.handleError(
          '[SaveDrivingTestQuizResult] mutation failed',
          error,
        )
      }
      return
    } else {
      const studentContext: StudentContext = this.studentContext
      const result: DrivingTestQuizResults = {
        ...results,
        category,
      }

      if (
        this.assignments.currentTask?.__typename ===
          'AssignmentTaskDrivingTests' &&
        this.isAssignmentMode
      ) {
        const {
          gradePosition,
          assignmentUuid,
          id: taskId,
        } = this.assignments.currentTask

        result.grade_position = gradePosition
        studentContext.taskId = taskId
        studentContext.assignmentUuid = assignmentUuid
      }

      await this.operations.completeDrivingTestsQuiz(studentContext, result)

      await this.assignments.fetch()

      return this.studentProgress.fetchProgress()
    }
  }
}

declare module '@ember/controller' {
  interface Registry {
    'driving-tests/quiz': DrivingTestsQuizController
  }
}
