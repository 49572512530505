import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type { ModelFor } from 're-client/utils/route-model'
import type { FeatureService } from '@blakeelearning/features'
import type StudentProgressService from 're-client/services/student-progress'
import type LessonsMapQuizRoute from 're-client/routes/lessons/map/quiz'
import type CertificateService from 're-client/services/certificate'
import { debugAction } from 're-client/utils/debug'
import type CaperLoaderService from 're-client/services/caper-loader'
import type JesterLoaderService from 're-client/services/jester-loader'
import type ErrorHandlerService from 're-client/services/error-handler'
import type QuestService from 're-client/services/quest'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import { v4 as uuidV4 } from 'uuid'

type CategoryAreaKey = string
type CategoryAreaResults = Record<'correct' | 'incorrect', string[]>
interface MapQuizResults {
  correctCount: number
  totalCount: number
  correctAnswers: string[]
  incorrectAnswers: Record<string, string>
  categoryResults: Record<CategoryAreaKey, CategoryAreaResults>
}

const {
  APP: { lastMap },
} = config

function generateResult(correctCount: number) {
  const result = {
    correctCount,
    totalCount: 15,
    correctAnswers: [] as string[],
    incorrectAnswers: {} as Record<string, string>,
    categoryResults: {
      'Comprehension Skills; Read text with accuracy and understanding.': {
        correct: [
          'Tuesday was a cloudy day.',
          'Lions pounce on their food.',
          'Flowers need water to grow.',
        ],
        incorrect: [],
      },
      'High-Frequency Sight Words; Read common high-frequency words.': {
        correct: ['was day Tuesday', 'their food'],
        incorrect: [],
      },
      'Phonics and Word Recognition; Associate long vowel sounds with their common spellings.':
        {
          correct: ['ay', 'ight', 'ore', 'ay', 'oat'],
          incorrect: [],
        },
      'Text Features; Identify and understand grammar terms.': {
        correct: ['nouns', 'verbs', 'compound words'],
        incorrect: [],
      },
      'Comprehension Skills; Answer questions about key details.': {
        incorrect: ['literal'],
        correct: ['inferential'],
      },
      'Phonics and Word Recognition; Understand and use letter-sound correspondence.':
        {
          correct: ['soft c pounce'],
          incorrect: [],
        },
      'Phonics and Word Recognition; Read and produce words with inflectional endings/suffixes.':
        {
          correct: ['add ed, chew chewed'],
          incorrect: [],
        },
      'Phonemic and Phonological Awareness; Identify or produce rhyming words.':
        {
          correct: [
            'light night bright sight',
            'score wore store shore',
            'okay day stay',
            'boat coat float goat',
          ],
          incorrect: [],
        },
      'Phonics and Word Recognition; Decode and read three-syllable words.': {
        correct: ['flowerpot'],
        incorrect: [],
      },
      'Comprehension Skills; Answer questions about the meaning of words in a text.':
        {
          correct: ['opposites cool warm'],
          incorrect: [],
        },
    } satisfies Record<CategoryAreaKey, CategoryAreaResults>,
  }

  for (let i = 1; i <= result.totalCount; i++) {
    if (i <= correctCount) {
      result.correctAnswers.push(`debug_map_quiz_${i}`)
    } else {
      result.incorrectAnswers[`debug_map_quiz_${i}`] = `wrong_${i}`
    }
  }

  return result
}

export const saveReadingMapQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveReadingMapQuizResult($input: ReadingMapQuizResultCreateInput!) {
    readingMapQuizResultCreate(input: $input) {
      student {
        id
        ...StudentProgressFragment
        ...AssignmentTask
        ...StudentQuestDataFragment
        ...QuestGoalEssentialDataFragment
        pendingCertificate {
          ...PendingCertificateFragment
        }
      }
    }
  }
`)

/**
 * End-of-map quiz activity, not to be confused with the end-of-lesson
 * quiz activity
 *
 * @class lessonsMapQuizActivity
 */
export default class LessonsMapQuizController extends Controller {
  @service declare certificate: CertificateService

  @service declare studentProgress: StudentProgressService

  @service declare user: UserService

  @service declare router: RouterService

  @service declare features: FeatureService

  @service declare caperLoader: CaperLoaderService

  @service declare jesterLoader: JesterLoaderService

  @service declare errorHandler: ErrorHandlerService

  @service declare quest: QuestService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  declare model: ModelFor<LessonsMapQuizRoute>

  saveReadingMapQuizResultMutation = useMutation(
    this,
    saveReadingMapQuizResultMutationDocument,
  )

  get currentMap() {
    return this.model.mapId
  }

  get renderQuestAlert() {
    return this.features.isEnabled('quests') && this.quest.showQuestAlert
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: '15/15 - Gold Certificate', value: '15' },
        { label: '14/15 - Gold Certificate', value: '14' },
        { label: '13/15 - Silver Certificate', value: '13' },
        { label: '12/15 - Silver Certificate', value: '12' },
        { label: '11/15 - Bronze Certificate', value: '11' },
      ],
      values: {
        15: generateResult(15),
        14: generateResult(14),
        13: generateResult(13),
        12: generateResult(12),
        11: generateResult(11),
      },
    },
  })
  async passQuiz({ results }: { results: MapQuizResults }) {
    await this.quizCompleted(results)
    await this.next()
  }

  @action
  @debugAction()
  async failQuiz() {
    await this.quizCompleted(generateResult(0))
    await this.next()
  }

  /**
   * Saves the quiz results as a map quiz complete event to the backend and checks the response for a pendingCertificate
   * then updates the GetStudent query in the apollo cache.
   */
  @action
  async quizCompleted(results: MapQuizResults, uuid?: string) {
    try {
      const data = await this.saveReadingMapQuizResultMutation.current.mutate({
        variables: {
          input: {
            attemptUuid: uuid ?? uuidV4(),
            categoryResults: results.categoryResults,
            correctAnswers: results.correctAnswers,
            incorrectAnswers: results.incorrectAnswers,
            quiz: this.currentMap,
          },
        },
      })

      if (!data)
        throw new Error(
          '[SaveReadingMaoQuizResult] No data returned from mutation',
        )
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveReadingMaoQuizResult] mutation failed',
        error,
      )
    }
    this.interactive.callInteractionMethod('nextable')
  }

  /**
   * Transitions to the next place. Which could be:
   * - the certificate route - if they have earned a pending certificate
   * - the finished-re-lessons route - if they have finished all lessons
   * - the next map
   */
  @action
  @debugAction()
  async next() {
    await this.studentProgress.fetchProgress()

    let nextRoute: [string] = ['lessons.finished-re-lessons']

    if (this.studentProgress.lessonsCurrentMap <= lastMap) {
      nextRoute = ['lessons.map.next']
    }

    if (!this.features.isEnabled('bypass_certificate_save')) {
      const hasPendingCertificate =
        await this.certificate.hasPendingCertificate()
      if (hasPendingCertificate) {
        nextRoute = ['lessons.certificate']
      }
    }

    this.quest.displayQuestAlertIfRequired(nextRoute)
  }

  /**
   * Adds one to the student's eggs count
   */
  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    let amount

    if (typeof args === 'number') {
      amount = args
    } else {
      amount = args.amount
    }

    this.user.incrementEggs(amount)
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/map/quiz': LessonsMapQuizController
  }
}
